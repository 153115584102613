<template>
  <div class="memberCenterPage">
    <iframe
      :src="url"
      frameborder="0"
      marginheight="0"
      class="website"
      name="parentPage"
    ></iframe>
  </div>
</template>

<script>
import { AiLaDomainNames } from "~/baseUtils/enumValue";
export default {
  metaInfo: {
    title: "控制台",
  },
  created() {
    let path = "account/userInfo";
    if (this.$route.query.url) {
      path = this.$route.query.url;
    }
    this.url = `${
      AiLaDomainNames[process.env.VUE_APP_API_ENV][this.LOCALE]
    }/memberCenter/${path}?isSIF=true&initialParams=${this.USER_ID}&type=siffa`;
    window.addEventListener("message", (e) => {
      if (e.data.event == "MODIFY_PASSWORD") {
        setTimeout(() => {
          this.$store.commit("baseStore/CLEAR_USER_INFO");
          this.$router.push("/sign");
        }, 2000);
      }
      if (e.data.event == "COMPANYNAME_CLICK") {
        let res = this.$router.resolve({
          path: "/companyDetail",
          query: {
            parameter: this._encode({
              company_id: e.data.id,
            }),
          },
        });
        window.open(res.href, "_blank");
      }
    });
  },
  data() {
    return {
      url: "",
    };
  },
};
</script>

<style lang="less" scoped>
.personCenterPage {
  height: 820px;
  overflow-y: hidden;
}
.website {
  width: 100%;
  height: 820px;
}
</style>